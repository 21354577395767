import isArray from 'lodash/isArray';

import getFilterWithCol from '../../helpers/getFilterWithCol';
import Types from '../../modules/types';

import {
  filterPagination,
  filterLocation,
} from '../common';

const { LANG_KEY: langKey, helpers: { getTranslation } } = Types;

const getValueForFieldDefault = value => (
  value && value.length
  ? value
  : undefined
);

export const filterSort = ['FilterHidden', {
  id: 'sort',
  build: builder => builder.add(
    'sort',
    { createdAt: -1 },
  ),
}];

export const pagination = ['FilterPagination', {
  id: 'pagination',
  section: 'bottom',
}];

export const populateOwner = ['FilterHidden', {
  id: 'populate',
  build: builder => builder.add(
    'populate',
    { owner: true },
  ),
}];

export const filterName = ['FilterField', {
  id: 'name',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => value && builder.add(
    'where',
    { name: { REGEX: value, OPTIONS: 'i' } },
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'name',
    placeholder: getTranslation('find_by_name', langKey),
  }],
}];

export const filterIdNumber = ['FilterField', {
  id: 'idNumber',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => value && builder.add(
    'where',
    { idNumber: { REGEX: value, OPTIONS: 'i' } },
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'idNumber',
    placeholder: getTranslation('find_by_id_number', langKey),
  }],
}];

export const filterBreed = ['FilterField', {
  id: 'breed',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => value && builder.add(
    'where',
    { breed: { REGEX: value, OPTIONS: 'i' } },
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'breed',
    placeholder: getTranslation('find_by_breed', langKey),
  }],
}];

export const filterChartNumber = ['FilterField', {
  id: 'chartNumber',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => value && builder.add(
    'where',
    { chartNumber: value },
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'chartNumber',
    placeholder: getTranslation('find_by_chart_number', langKey),
  }],
}];

export const filterOwner = ['FilterField', {
  id: 'owner',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { owner: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'owner',
    url: '/owners',
    placeholder: getTranslation('find_by_owner', langKey),
    // mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    // getChoiceLabel: choice => `${(choice.data.name)}`,
  }],
}];

const FILTERS = [
  ...[
    [filterName, 12],
    [filterOwner, 12],

    [filterIdNumber, 12],
    [filterBreed, 12],
    [filterLocation, 24],
  ].map(([filter, col]) => getFilterWithCol(filter, col)),

  filterSort,
  filterPagination,
  populateOwner,
];

export default FILTERS;
