/* eslint-disable import/prefer-default-export */

import React from 'react';
import isArray from 'lodash/isArray';
import moment from 'moment';
import { Link } from 'hive-admin/node_modules/react-router-dom';

import {
  getLinkWithSearchFields,
} from '../components/Links';
import getFilterWithCol from '../helpers/getFilterWithCol';

import Types from '../modules/types';

import {
  filterSearch,
  filterPagination,
  filterLocation,
} from '../routes/common';

const { LANG_KEY: langKey, helpers: { getTranslation } } = Types;

export const populateAll = ['FilterHidden', {
  id: 'populate',
  build: builder => builder.add(
    'populate',
    { owner: true, intervention: true },
  ),
}];

export const [filterFrom, filterTo] = [
  ['from', 'After This Date', 'After', 'GTE'],
  ['to', 'Before This Date', 'Before', 'LTE'],
// eslint-disable-next-line no-unused-vars
].map(([id, placeholder, label, operator]) => [
  'FilterField', {
    id,
    label: null,
    section: 'top',
    buildQuery: (value, builder) => value && builder.add(
      'where',
      { createdAt: { [operator]: value } },
    ),
    getValueForField: value => (value ? moment(value) : undefined),
    getValueForQuery: value => (
      value
      ? (
          id === 'from'
          ? moment(value).startOf('day')
          : moment(value).endOf('day')
        ).toJSON()
      : undefined
    ),
    field: ['FieldDatePicker', {
      name: id,
      placeholder,
      showToday: false,
      format: ['DD.MM.YYYY.'],
      renderExtraFooter: () => {
        const now = moment();
        return (
          <div>
            <span>
              {id === 'from' ? 'Start of this: ' : 'End of this: '}
            </span>
            {[
              ['Day', 'day'],
              ['Week', 'week'],
              ['Month', 'month'],
              ['Year', 'year'],
            ].reduce(
              (agr, [footerLabel, range], i, array) => {
                agr.push(
                  <span key={range}>
                    <Link
                      to={getLinkWithSearchFields(
                        // eslint-disable-next-line no-undef
                        window.location,
                        [
                          {
                            name: id,
                            value: (
                                id === 'from'
                              ? now.startOf(range)
                              : now.endOf(range)
                            ).toJSON(),
                            replace: true,
                          },
                        ],
                        { pathname: '/transactions' },
                      )}
                    >
                      {footerLabel}
                    </Link>
                  </span>
                );
                if (i < array.length - 1) {
                  // eslint-disable-next-line react/no-array-index-key
                  agr.push(<span key={`sep${i}`}> / </span>);
                }
                return agr;
              },
              [],
            )}
          </div>
        );
      },
    }],
  },
]);

const getValueForFieldDefault = value => (
  value && value.length
  ? value
  : undefined
);

export const filterSortHidden = ['FilterHidden', {
  id: 'sort',
  build: builder => builder.add(
    'sort',
    { createdAt: -1 },
  ),
}];

export const filterSort = ['FilterField', {
  id: 'sort',
  label: null,
  section: 'top',
  VALUES_MAP: {
    dateDes: { createdAt: -1 },
    dateAsc: { createdAt: 1 },
    amount: { amount: 1 },
  },
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('sort', { ...filter.VALUES_MAP[value] })
    : builder.add('sort', { ...filter.VALUES_MAP.dateDes })
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldSelect', {
    name: 'sort',
    placeholder: 'Sort By',
    defaultValue: 'dateDes',
    initialValue: 'dateDes',
    prepareValueForInput: value => (value ? value : 'dateDes'),
    choices: [{
      label: 'Date Ascending',
      value: 'dateAsc',
    }, {
      label: 'Date Descending',
      value: 'dateDes',
    }, {
      label: 'Amount',
      value: 'amount',
    }],
  }],
}];

export const filterOwner = ['FilterField', {
  id: 'owner',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { owner: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'owner',
    url: '/owners',
    placeholder: getTranslation('find_by_owner', langKey),
    // mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    // getChoiceLabel: choice => `${(choice.data.name)}`,
  }],
}];

export const [filterAbove, filterUnder] = [
  ['above', 'Amount More Than', 'More Than', 'GTE'],
  ['under', 'Amount Less Than', 'Less Than', 'LTE'],
// eslint-disable-next-line no-unused-vars
].map(([id, placeholder, label, operator]) => [
  'FilterField', {
    id,
    label: null,
    section: 'top',
    buildQuery: (value, builder) => value && builder.add(
      'where',
      { amount: { [operator]: parseFloat(value, 10) || 0 } },
    ),
    getValueForField: value => value || '',
    getValueForQuery: value => (
      !value
      ? undefined
      : value.target
      ? value.target.value
      : value
    ),
    field: ['FieldText', {
      name: id,
      placeholder,
      addonAfter: Types.CURRENCY,
      style: { textAlign: 'right' },
      textAlign: 'right',
      align: 'right',
    }],
  },
]);

const FILTERS = [
  ...[
    [filterSearch, 12],
    [filterOwner, 12],
    [filterLocation, 24],

    // [filterUnder, 8],
    // [filterTo, 8],

    // [filterSort, 8],

    // [filterAbove, 8],
    // [filterFrom, 8],
  ].map(([filter, col]) => getFilterWithCol(filter, col)),

  filterSortHidden,
  populateAll,
  filterPagination,
];

export default FILTERS;
