/* global window, document */
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import Admin from 'hive-admin';
import PageLogin from 'hive-admin/src/components/PageLogin';
import PagePasswordReset from 'hive-admin/src/components/PagePasswordReset';

import './antd.less';
import './admin.less';

// import './components/DividerText';
// import './components/Card';
// import './components/FilterField';
// import './components/FieldDatePicker';
// import './components/FieldRadio';
// import './components/FieldTable';

// import './components/ActionWithFormModal';

import UsersPage from './routes/users';
import OwnersPage from './routes/owners';
import AnimalsPage from './routes/animals';
import ServicesPage from './routes/services';
import TransactionsPage from './routes/transactions';
import InterventionsPage from './routes/interventions';
import DrugsPage from './routes/drugs';
import LocationsPage from './routes/locations';

import { renderLogo, renderSidebarLogo } from './components/Logo';
import theme from './theme';

import Types from './modules/types';

const { helpers: { getTranslation } } = Types;

// const IS_ADMIN = /^\/admin/.test(window.location.pathname);

const toEmailOnlyField = ([ClassName, config = {}]) => [
  ClassName,
  { ...config, name: 'email', placeholder: 'Email' },
];

window.TYPES = Types;

const {
  REACT_APP_NODE_ENV,
  REACT_APP_SERVER_HOST,
  REACT_APP_SERVER_PROTOCOL,
  REACT_APP_HTTP_PORT,
  REACT_APP_API_PATH,
  REACT_APP_PUBLIC_URL = '',
} = process.env;

const SERVER_URL = `${
  REACT_APP_SERVER_PROTOCOL
}://${
  REACT_APP_SERVER_HOST
}${
  REACT_APP_NODE_ENV === 'development'
  ? `:${REACT_APP_HTTP_PORT}`
  : ''
}`;

const base = window.ADMIN_BASE = `${REACT_APP_PUBLIC_URL}`;
const restBase = `${SERVER_URL}${REACT_APP_API_PATH}`;

const admin = Admin.create({
  base,
  restBase,
  restBaseServer: `${SERVER_URL}${REACT_APP_API_PATH}`,
  sidebarProps: {
    renderLogo: renderSidebarLogo,
    logoutLabel: getTranslation('logout'),
    collapseLabel: getTranslation('collapse'),
    expandLabel: getTranslation('expand'),
  },
  enablePasswordReset: true,
  passwordSetSuccessRedirectPath: '/login',
  enableSignup: false,
  titlePrefix: 'Minić Vet | ',
  validateViewer: (viewer) => {
    if (['ADMIN', 'VETERINARIAN'].indexOf(viewer.role) === -1) {
      throw new Error('Invalid credentials');
    }
    return viewer;
  },
  structure: [
    ['PageLogin', {
      renderBeforeForm: renderLogo,
      fields: [
        toEmailOnlyField(PageLogin.config.fields[0]),
        ...PageLogin.config.fields.slice(1),
      ],
      redirect: [['redirect.authorized']],
    }],
    ['Page', {
      hidden: true,
      path: '/',
      exact: true,
      redirect: [['redirect.always', '/animals']],
    }],
    ['PagePasswordReset', {
      renderBeforeForm: renderLogo,
      fields: [
        toEmailOnlyField(PagePasswordReset.config.fields[0]),
        ...PagePasswordReset.config.fields.slice(1),
      ],
      redirect: [['redirect.authorized']],
    }],
    ['PagePasswordSet', { renderBeforeForm: renderLogo }],
    ['PageAccountActivation', {
      renderBeforeForm: renderLogo,
    }],
    ...UsersPage,
    ...LocationsPage,
    ...OwnersPage,
    ...AnimalsPage,
    ...InterventionsPage,
    ...TransactionsPage,
    ...DrugsPage,
    ...ServicesPage,
    ['Page404', {
      redirect: [['redirect.unauthorized']],
    }],
  ],
});

ReactDOM.render(
  (
    <ThemeProvider theme={theme}>
      {admin}
    </ThemeProvider>
  ),
  document.getElementById('root'),
);
