import Admin from 'hive-admin';

import Types from '../modules/types';

import {
  ACTIONS,
  filterPagination,
  filterSortByName,
  filterSearch,
  cloneItem,
} from './common';

const { helpers: { getTranslation } } = Types;

export default Admin.compileFromLibrary(['GroupResource', {
  path: '/locations',
  loadUrl: '/locations',
  title: getTranslation('locations'),
  icon: 'home',
  redirect: [
    ['redirect.unauthorized'],
    ['condition.checkViewer', 'role', '!==', 'ADMIN'],
  ],
  hidden: [
    ['condition.checkViewer', 'role', '!==', 'ADMIN'],
  ],
  skip: [
    ['condition.checkViewer', 'role', '!==', 'ADMIN'],
  ],
  archiveConfig: {
    createButtonLabel: getTranslation('create_new_item'),
    icon: 'home',
    title: getTranslation('locations'),
    label: getTranslation('locations'),
    columns: [
      {
        path: 'name',
        title: getTranslation('name'),
        link: 'locations/:_id',
      },
    ],
    filters: [
      cloneItem(filterSearch, 24),

      filterSortByName,
      filterPagination,
    ],
  },
  singleConfig: {
    hidden: true,
    alias: '/locations',
    backButtonPaths: ['/locations'],
    headerTitle: getTranslation('create'),
  },
  singleCreateConfig: {
    title: getTranslation('locations'),
    actions: [ACTIONS.CREATE],
  },
  singleEditConfig: {
    title: getTranslation('locations'),
    actions: [ACTIONS.SAVE, ACTIONS.DELETE_ADMIN],
  },
  fields: [
    ['FieldText', {
      name: 'name',
      label: null,
      prefix: `${getTranslation('name')}:`,
      rules: [['validation.isRequired']],
    }],
  ],
}]).pages;
