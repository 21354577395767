// eslint-disable-next-line no-unused-vars
/* global window */
import React from 'react';

import getKey from 'lodash/get';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import moment from 'moment';

import Divider from 'antd/lib/divider';
import Descriptions from 'antd/lib/descriptions';
// eslint-disable-next-line max-len
import FieldConnectionSelect from 'hive-admin/src/components/FieldConnectionSelect';
import {
  parse as parseQuery,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from 'query-string';
import Types from '../../modules/types';

import {
  fieldLocation,
} from '../common';

const { DIAGNOSES, helpers: { getTranslation, decimalize } } = Types;

const isNotCreateActivity = ['condition.check', {
  path1: 'activity',
  op: '!==',
  value2: 'create',
}];

const TEXT_FIELDS = [
  ['anamnesis', getTranslation('anamnesis')],
  ['finding', getTranslation('finding')],
  ['recommendation', getTranslation('recommendation')],
  ['treatment', getTranslation('treatment')],
]
.reduce(
  (agr, [name, label]) => {
    agr.push(
      ['FieldTextArea', {
        name,
        label: `${label}:`,
        autosize: {
          minRows: 2, // TODO ?
        },
        // rules: [['validation.isRequired']],
      }],
    );
    return agr;
  },
  [],
);

export const fieldServices = ['FieldSortableList', {
  name: 'services',
  initialValue: [],
  addButtonLabel: `${getTranslation('add')}`,
  label: `${getTranslation('services')}`,
  fields: [
    ['FieldConnectionSelect', {
      name: 'service',
      label: null,
      prefix: `${getTranslation('service')}:`,
      url: '/services',
      getChoiceLabel: choice => `${
        choice.data.name
      } (${decimalize(choice.data.price)} ${Types.CURRENCY})`,
      searchPaths: ['name'],
      rules: [['validation.isRequired']],
      disabled: [isNotCreateActivity],
      virtual: [isNotCreateActivity],
    }],
    ['FieldText', {
      name: 'quantity',
      label: null,
      prefix: `${getTranslation('quantity')}:`,
      type: 'number',
      initialValue: 1,
      rules: [
        ['validation.isRequired'],
        ['validation.isNumber'],
      ],
    }],
  ],
}];

export const fieldDrugs = ['FieldSortableList', {
  name: 'drugs',
  initialValue: [],
  addButtonLabel: `${getTranslation('add')}`,
  label: `${getTranslation('drugs')}:`,
  fields: [
    ['FieldConnectionSelect', {
      name: 'drug',
      label: null,
      initialValue: [],
      prefix: `${getTranslation('drug')}:`,
      url: '/drugs',
      getChoiceLabel: choice => `${
        choice.data.name
      }${(
        choice.data.price
        ? (` (${
          decimalize(choice.data.price)
          } ${
            Types.CURRENCY
          })`
        )
        : ''
      )}`,
      searchPaths: ['name'],
      rules: [['validation.isRequired']],
      disabled: [isNotCreateActivity],
      virtual: [isNotCreateActivity],
    }],
    ['FieldText', {
      name: 'quantity',
      label: null,
      prefix: `${getTranslation('quantity')}:`,
      type: 'number',
      addonAfter: 'ml/pc',
      rules: [
        ['validation.isRequired'],
        ['validation.isNumber'],
      ],
    }],
  ],
}];

export const fieldCharges = ['FieldSortableList', {
  name: 'charges',
  addButtonLabel: `${getTranslation('add')}`,
  label: `${getTranslation('charges')}`,
  fields: [
    ['FieldText', {
      name: 'name',
      label: null,
      prefix: `${getTranslation('name')}:`,
      rules: [
        ['validation.isRequired'],
      ],
    }],
    ['FieldText', {
      name: 'price',
      label: null,
      prefix: `${getTranslation('price')}:`,
      addonAfter: Types.CURRENCY,
      type: 'number',
      rules: [
        ['validation.isRequired'],
        ['validation.isNumber'],
      ],
    }],
  ],
}];

export const fieldsPaid = [
  ['FieldSwitch', {
    name: 'paidFull',
    label: null,
    inlineLabelBefore: getTranslation('paid_full'),
    initialValue: true,
    rules: [
      ['validation.isRequired'],
    ],
    col: 12,
  }],
  ['FieldSwitch', {
    name: 'paidByCard',
    label: null,
    inlineLabelBefore: getTranslation('paid_by_card'),
    initialValue: false,
    col: 12,
  }],
  ['FieldText', {
    name: 'paid',
    label: null,
    initialValue: 0,
    prefix: `${getTranslation('paid')}:`,
    addonAfter: Types.CURRENCY,
    type: 'number',
    skip: [
      [
        'condition.testFieldValue',
        'paidFull',
        '===',
        true,
      ],
    ],
    rules: [
      // ['validation.isRequired'],
      ['validation.isNumber'],
    ],
  }],
  // ['FieldSwitch', {
  //   name: 'Sledeca vakcinacija?',
  // }],
  // ['FieldDatePicker', {
  //   name: 'Datum revakcine',
  // }],
];

export const CREATE = [
  fieldLocation,
  ['FieldConnectionSelect', {
    name: 'owner',
    label: null,
    prefix: `${getTranslation('owner')}:`,
    url: '/owners',
    // getChoiceLabel: choice => choice.data.name,
    searchPaths: ['name'],
    rules: [['validation.isRequired']],
    getInitialValue: (fieldConfig, formProps) => {
      if (formProps.data) {
        const value = getKey(formProps.data, fieldConfig.name);
        if (!isUndefined(value)) {
          return value;
        }
      }
      const { owner } = parseQuery(formProps.location.search);
      if (owner) {
        return `${owner}`;
      }
      return fieldConfig.initialValue;
    },
    renderChoice: (choice, field, props) => {
      const render = FieldConnectionSelect.config.renderChoice(
        choice,
        field,
        props,
      );
      return React.cloneElement(
        render,
        {
          onClick: () => {
            if (props.form) {
              props.form.setFieldsValue({
                animal: undefined,
              });
            }
          },
        },
      );
    },
  }],
  ['FieldConnectionSelect', {
    name: 'animal',
    label: null,
    prefix: `${getTranslation('pet')}:`,
    url: '/animals',
    // extraQueryProps: { populate: { owner: true } },
    getChoiceLabel: choice => (
      choice.data.alive
      ? `${choice.data.name}`
      : (
        <span style={{ textDecoration: 'line-through' }}>
          {choice.data.name}
        </span>
      )
    ),
    getExtraQueryConditions: (props) => {
      const owner = props.form.getFieldValue('owner');
      return owner
      ? [{ owner }]
      : [{ name: '_______' }];
    },
    searchPaths: ['name'],
    rules: [['validation.isRequired']],
    getInitialValue: (fieldConfig, formProps) => {
      if (formProps.data) {
        const value = getKey(formProps.data, fieldConfig.name);
        if (!isUndefined(value)) {
          return value;
        }
      }
      const { animal } = parseQuery(formProps.location.search);
      if (animal) {
        return `${animal}`;
      }
      return fieldConfig.initialValue;
    },
  }],
  ['FieldDatePicker', {
    name: 'createdAt',
    label: `${getTranslation('date')}:`,
    placeholder: null,
    prepareValueForInput: value => (value ? moment(value) : moment()),
    format: ['DD.MM.YYYY.'],
  }],
  ['Divider', { name: 'textFieldsDivider' }],
  ['FieldSelect', {
    name: 'diagnosis',
    label: null,
    showSearch: true,
    allowClear: true,
    mode: 'tags',
    prefix: `${getTranslation('diagnosis')}:`,
    rules: [],
    optionFilterProp: 'children',
    choices: DIAGNOSES,
    filterOption: (input, option) => (option.props.children.toLowerCase()
      .replace(/š/ig, 's')
      .replace(/[čć]/ig, 'c')
      .replace(/ž/ig, 'z')
      .indexOf(input.toLowerCase()) >= 0
    ),
  }],
  ...TEXT_FIELDS,
  ['Divider', { name: 'textFieldsDividerTwo' }],
  fieldServices,
  fieldDrugs,
  fieldCharges,
  // ['FieldText', {
  //   name: 'total',
  //   label: null,
  //   prefix: `${getTranslation('total')}:`,
  //   addonAfter: Types.CURRENCY,
  //   type: 'number',
  //   disabled: [() => true],
  //   getInitialValue: (fieldConfig, formProps) => {
  //     const services = formProps.form.getFieldValue('services');
  //     console.log(services);
  //   },
  // }],
  ['FieldConnectionSelect', {
    name: 'createdBy',
    label: null,
    prefix: `${getTranslation('created_by')}:`,
    url: '/users',
    getChoiceLabel: choice => `${choice.data.name}`,
    getInitialValue: (fieldConfig, formProps) => {
      if (formProps?.viewer?._id) {
        return formProps.viewer._id;
      }
      return fieldConfig.initialValue;
    },
    searchPaths: ['name'],
    rules: [['validation.isRequired']],
    disabled: [isNotCreateActivity],
    virtual: [isNotCreateActivity],
  }],
  ...fieldsPaid,
];

export const EDIT = [
  ['FieldReact', {
    renderContent: props => (
      [
        [getTranslation('details'), [
          [getTranslation('owner'), props.data.owner.name],
          [getTranslation('pet'), props.data.animal.name],
          [
            getTranslation('date'),
            moment(props.data.createdAt).format('DD.MM.YYYY.'),
          ],
        ]],
        [getTranslation('charges'), [
          ...props.data.services.reduce(
            (agr, service) => {
              agr.push([
                getTranslation('service'),
                `${service.quantity
                } | ${
                  service.name
                } | ${
                  decimalize(service.price * service.quantity)
                } ${
                  Types.CURRENCY
                }`,
              ]);
              return agr;
            },
            [],
          ),
          ...props.data.drugs.reduce(
            (agr, drug) => {
              agr.push([
                getTranslation('drug'),
                `${
                  drug.quantity
                } | ${
                  drug.name
                } | ${
                  decimalize(drug.price * drug.quantity)
                } ${
                  Types.CURRENCY
                }`,
              ]);
              return agr;
            },
            [],
          ),
          ...props.data.charges.reduce(
            (agr, charge) => {
              agr.push([
                getTranslation('additional_charge'),
                `${
                  charge.name
                } (${
                  decimalize(charge.price)
                } ${
                  Types.CURRENCY
                })`,
              ]);
              return agr;
            },
            [],
          ),
          [
            getTranslation('total'),
            `${decimalize(props.data.amount)} ${Types.CURRENCY}`,
          ],
          [
            getTranslation('paid'),
            `${decimalize(props.data.paid)} ${Types.CURRENCY}`,
          ],
        ]],
      ]
      .reduce(
        (sections, section, index, initialSectionsArray) => {
          if (section) {
            sections.push(
              <Descriptions key={section[0]} title={section[0]} column={1}>
                {
                  isArray(section[1])
                  ? section[1].reduce(
                      (items, item) => {
                        if (item) {
                          items.push(
                            <Descriptions.Item key={item[0]} label={item[0]}>
                              {item[1]}
                            </Descriptions.Item>
                          );
                        }
                        return items;
                      },
                      [],
                    )
                  : section[1]
                }
              </Descriptions>
            );
            if (index < initialSectionsArray.length - 1) {
              sections.push(<Divider key={`${section[0]}Divider`} />);
            }
          }
          return sections;
        },
        [],
      )
    ),
  }],
  ['Divider', { name: 'textFieldsDivider' }],
  ['FieldDatePicker', {
    name: 'createdAt',
    label: `${getTranslation('date')}:`,
    placeholder: null,
    prepareValueForInput: value => (value ? moment(value) : moment()),
    format: ['DD.MM.YYYY.'],
  }],
  ['FieldSelect', {
    name: 'diagnosis',
    label: null,
    showSearch: true,
    allowClear: true,
    mode: 'multiple',
    prefix: `${getTranslation('diagnosis')}:`,
    rules: [],
    optionFilterProp: 'children',
    choices: DIAGNOSES,
    filterOption: (input, option) => (option.props.children.toLowerCase()
      .replace(/š/ig, 's')
      .replace(/[čć]/ig, 'c')
      .replace(/ž/ig, 'z')
      .indexOf(input.toLowerCase()) >= 0
    ),
  }],
  ...TEXT_FIELDS,
];

export default {
  CREATE,
  EDIT,
};
