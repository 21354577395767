import Admin from 'hive-admin';

import Types from '../../modules/types';

import FILTERS from './filters';
import COLUMNS from './columns';
import {
  CREATE,
  EDIT,
  fieldServices,
  fieldDrugs,
  fieldCharges,
  fieldsPaid,
} from './fields';

import { ACTIONS, cloneItem } from '../common';

const { helpers: { getTranslation } } = Types;

export default Admin.compileFromLibrary(['GroupResource', {
  title: getTranslation('interventions'),
  icon: 'file',
  path: '/interventions',
  redirect: [['redirect.unauthorized']],
  archiveConfig: {
    createButtonLabel: getTranslation('create_new_item'),
    icon: 'file',
    title: getTranslation('interventions'),
    label: getTranslation('interventions'),
    filters: FILTERS,
    columns: COLUMNS,
  },
  singleConfig: {
    hidden: true,
    alias: '/interventions',
    backButtonPaths: ['/interventions'],
    backButtonLabel: getTranslation('close'),
    headerTitle: getTranslation('create'),
  },
  singleCreateConfig: {
    title: getTranslation('interventions'),
    fields: CREATE,
    actions: [ACTIONS.CREATE],
  },
  singleEditConfig: {
    title: getTranslation('interventions'),
    loadUrl: 'interventions/:id/?populateAll=true', // TODO maybe actually save data about owner / animal in intervention
    actions: [
      ACTIONS.SAVE,
      ['ActionWithFormModal', {
        label: `${getTranslation('update')}`,
        title: `${getTranslation('update')}`,
        modalProps: {
          centered: true,
          closable: true,
          wrapClassName: 'edit-modal',
        },
        fields: [
          cloneItem(fieldServices, null, null, { name: 'servicesEdit' }),
          cloneItem(fieldDrugs, null, null, { name: 'drugsEdit' }),
          cloneItem(fieldCharges, null, null, { name: 'chargesEdit' }),
          ...fieldsPaid,
        ],
        actions: [
          ['ActionWithFormBasedRequest', {
            title: getTranslation('add'),
            skip: [],
            handleSuccess: (_data, props) => props.reload(),
            getRequestConfig: (props, data) => ({
              url: `interventions/${props.data._id}/edit-charges`,
              method: 'post',
              data,
            }),
          }],
        ],
      }],
      ACTIONS.DELETE,
    ],
    fields: EDIT,
  },
}]).pages;
