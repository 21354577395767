import Admin from 'hive-admin';

import Types from '../modules/types';

import {
  ACTIONS,
  filterPagination,
  filterSortByName,
  filterSearch,
  filterLocation,
  fieldLocation,
  cloneItem,
} from './common';

const { helpers: { getTranslation, decimalize } } = Types;

export default Admin.compileFromLibrary(['GroupResource', {
  path: '/services',
  loadUrl: '/services',
  title: getTranslation('services'),
  icon: 'menu',
  redirect: [['redirect.unauthorized']],
  archiveConfig: {
    createButtonLabel: getTranslation('create_new_item'),
    icon: 'menu',
    title: getTranslation('services'),
    label: getTranslation('services'),
    columns: [
      {
        path: 'name',
        title: getTranslation('name'),
        link: 'services/:_id',
      },
      {
        path: 'price',
        title: getTranslation('price'),
        render: record => `${decimalize(record.price)} ${Types.CURRENCY}`,
      },
    ],
    filters: [
      cloneItem(filterSearch, 24),
      filterLocation,

      filterSortByName,
      filterPagination,
    ],
  },
  singleConfig: {
    hidden: true,
    alias: '/services',
    backButtonPaths: ['/services'],
    headerTitle: getTranslation('create'),
  },
  singleCreateConfig: {
    title: getTranslation('services'),
    actions: [ACTIONS.CREATE],
  },
  singleEditConfig: {
    title: getTranslation('services'),
    actions: [ACTIONS.SAVE, ACTIONS.DELETE_ADMIN],
  },
  fields: [
    fieldLocation,
    ['FieldText', {
      name: 'name',
      label: null,
      prefix: `${getTranslation('name')}:`,
      rules: [['validation.isRequired']],
    }],
    ['FieldText', {
      name: 'price',
      label: null,
      prefix: `${getTranslation('price')}:`,
      type: 'number',
      rules: [['validation.isRequired']],
    }],
  ],
}]).pages;
