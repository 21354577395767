import React from 'react';
import isArray from 'lodash/isArray';
import isFinite from 'lodash/isFinite';
import getKey from 'lodash/get';
import { Link } from 'hive-admin/node_modules/react-router-dom';
import {
  stringify as stringifyQuery,
  parse as parseQuery,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from 'query-string';

import moment from 'moment';

import Popover from 'antd/lib/popover';

import Types from '../modules/types';

const { helpers: { getTranslation, decimalize } } = Types;

export function lowerCaseRestLetters(word) {
  return `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`;
}

export function formatOrderStatus(subject) {
  return (
    subject.status
    ? subject.status
    : subject
  ).split('_').map(item => lowerCaseRestLetters(item)).join(' ');
}

export function formatAmount(amount, currency = Types.CURRENCY) {
  return `${
    decimalize(amount)
  } ${
    currency
  }`;
}

export function formatIndexAmount(amount) {
  return `${
    (
      isFinite(amount)
      ? amount.toFixed(0)
      : `${amount}`
    ).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }`;
}

export function formatMonetaryAmount(amount, currency = Types.CURRENCY) {
  return `${
    (
      isFinite(amount)
      ? amount.toFixed(2)
      : `${amount}`
    ).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  } ${
    currency
  }`;
}

export function renderOrderAmount(subject, valuePath) {
  const output = [
    <span key={Types.CURRENCY} style={{ whiteSpace: 'nowrap' }}>
      {formatAmount(getKey(subject, valuePath, 0), Types.CURRENCY)}
    </span>,
  ];
  return (
    <div style={{ textAlign: 'right' }}>
      {output}
    </div>
  );
}

export function getLinkWithSearchFields(
  location,
  fields = [],
  extras = {},
) {
  const search = parseQuery(
    location
    ? location.search.slice(1)
    : ''
  );
  fields.forEach(({ name, value, replace = false }) => {
    if (replace) {
      search[name] = value;
    } else {
      search[name] = (
        !search[name]
        ? []
        : isArray(search[name])
        ? search[name]
        : [search[name]]
      )
      .filter(testValue => (
        isArray(value)
        ? !value.includes(testValue)
        : value !== testValue
      ));
      search[name].push(...isArray(value) ? value : [value]);
    }
  });
  return { ...location, ...extras, search: `?${stringifyQuery(search)}` };
}

export function getSubjectUrl(pathname, subject) {
  return `${pathname}/${subject._id || subject}`;
}

export function OwnerInterventionLink({
  location,
  subject,
  searchLabel,
  filterName = 'owner',
  children,
}) {
  if (!subject) {
    return 'N / A';
  }
  if (!searchLabel) {
    searchLabel = 'Show interventions for this owner';
  }
  return (
    <Popover
      title={null}
      content={(
        <div>
          <span>
            {`${
              getTranslation('show_all')
            } ${
              getTranslation('interventions').toLowerCase()
            } `}
          </span>
          <Link
            to={getLinkWithSearchFields(
              location,
              [{ name: filterName, value: subject._id }],
              { pathname: '/interventions' },
            )}
          >
            {getTranslation('for_this_owner')}
          </Link>
        </div>
      )}
    >
      <Link to={getSubjectUrl('/owners', subject)}>
        {
          children
          ? children
          : `${
              subject.name || subject.username
            }`
        }
      </Link>
    </Popover>
  );
}
export function OwnerTransactionLink({
  location,
  subject,
  searchLabel,
  filterName = 'owner',
  children,
}) {
  if (!subject) {
    return 'N / A';
  }
  if (!searchLabel) {
    searchLabel = 'Show transactions for this owner';
  }
  return (
    <Popover
      title={null}
      content={(
        <div>
          <span>
            {`${
              getTranslation('show_all')
            } ${
              getTranslation('transactions').toLowerCase()
            } `}
          </span>
          <Link
            to={getLinkWithSearchFields(
              location,
              [{ name: filterName, value: subject._id }],
              { pathname: '/transactions' },
            )}
          >
            {getTranslation('for_this_owner')}
          </Link>
        </div>
      )}
    >
      <Link to={getSubjectUrl('/owners', subject)}>
        {
          children
          ? children
          : `${
              subject.name || subject.username
            }`
        }
      </Link>
    </Popover>
  );
}
export function OwnerAnimalLink({
  location,
  subject,
  filterName = 'owner',
  children,
}) {
  if (!subject) {
    return 'N / A';
  }
  return (
    <Popover
      title={null}
      content={(
        <div>
          <span>
            {`${
              getTranslation('show_all_pets')
            }`}
            {' '}
          </span>
          <Link
            to={getLinkWithSearchFields(
              location,
              [{ name: filterName, value: subject._id }],
              { pathname: '/animals' },
            )}
          >
            {getTranslation('for_this_owner')}
          </Link>
        </div>
      )}
    >
      <Link to={getSubjectUrl('/owners', subject)}>
        {
          children
          ? children
          : `${
              subject.name || subject.username
            }`
        }
      </Link>
    </Popover>
  );
}
export function AnimalInterventionLink({
  location,
  subject,
  searchLabel,
  filterName = 'animal',
  children,
}) {
  if (!subject) {
    return 'N / A';
  }
  if (!searchLabel) {
    searchLabel = 'Show interventions for this animal';
  }
  return (
    <Popover
      title={null}
      content={(
        <div>
          <span>
            {`${
              getTranslation('show_all')
            } ${
              getTranslation('interventions').toLowerCase()
            } `}
          </span>
          <Link
            to={getLinkWithSearchFields(
              location,
              [{ name: filterName, value: subject._id }],
              { pathname: '/interventions' },
            )}
          >
            {getTranslation('for_this_pet')}
          </Link>
        </div>
      )}
    >
      <Link to={getSubjectUrl('/animals', subject)}>
        {
          children
          ? children
          : `${
              subject.name || subject.username
            }`
        }
      </Link>
    </Popover>
  );
}

export function DateLink({
  location,
  date,
  children,
}) {
  date = moment(date);
  return (
    <Popover
      title={null}
      content={(
        <div>
          Show all interventions this:
          <span> </span>
          {
            ['day', 'week', 'month'].reduce(
              (agr, range, i, array) => {
                agr.push(
                  <Link
                    key={range}
                    to={getLinkWithSearchFields(
                      location,
                      [
                        {
                          name: 'from',
                          value: date.startOf(range).toJSON(),
                          replace: true,
                        },
                        {
                          name: 'to',
                          value: date.endOf(range).toJSON(),
                          replace: true,
                        },
                      ],
                      { pathname: '/interventions' },
                    )}
                  >
                    {range}
                  </Link>
                );
                if (i < array.length - 1) {
                  agr.push(<span key={`${range}-separator`}> / </span>);
                }
                return agr;
              },
              [],
            )
          }
        </div>
      )}
    >
      {
        children
        ? children
        : date.fromNow()
      }
    </Popover>
  );
}

export function OrderStatusLink({
  location,
  subject,
  searchLabel = 'Show orders with this status',
  children,
}) {
  return (
    <Popover
      title={null}
      content={(
        <Link
          to={getLinkWithSearchFields(
            location,
            [{ name: 'status', value: subject.status }],
            { pathname: '/orders' },
          )}
        >
          {searchLabel}
        </Link>
      )}
    >
      {
        children
        ? children
        : formatOrderStatus(subject)
      }
    </Popover>
  );
}

export function TransactionIDLink({
  // location,
  subject,
}) {
  const link = (
    <Link to={getSubjectUrl('/transactions', subject._id)}>
      {subject._id}
    </Link>
  );
  const [path, prefix] = (
      subject.reversalOf
    ? ['reversalOf', `Reversal of ${
        Types.TRANSACTION_TYPE_LABELS[subject.reversalOfType]
      }`]
    : subject.reversedBy
    ? ['reversedBy', 'Reversed by']
    : []
  );
  if (!path) {
    return link;
  }
  return (
    <Popover
      title={null}
      content={(
        <Link to={getSubjectUrl('/transactions', subject[path])}>
          {`${prefix} ${subject[path]._id}`}
        </Link>
      )}
    >
      {link}
    </Popover>
  );
}

export function AmountLink({
  location,
  subject,
  noun = 'orders',
  pathname = '/orders',
  valuePath = 'amount',
}) {
  return (
    <Popover
      title={null}
      content={(
        <div>
          <span>{`Show ${noun} `}</span>
          <Link
            to={getLinkWithSearchFields(
              location,
              [{
                name: 'above',
                value: getKey(subject, valuePath),
                replace: true,
              }],
              { pathname },
            )}
          >
            above
          </Link>
          <span> / </span>
          <Link
            to={getLinkWithSearchFields(
              location,
              [{
                name: 'under',
                value: getKey(subject, valuePath),
                replace: true,
              }],
              { pathname },
            )}
          >
            under
          </Link>
          <span> this amount</span>
        </div>
      )}
    >
      {renderOrderAmount(subject, valuePath)}
    </Popover>
  );
}
