import React from 'react';
import styled, { css } from 'styled-components';

import logoSrc from '../assets/logo.jpeg';

const WrapperHTML = ({ padding, ...props }) => <div {...props} />;

const Wrapper = styled(WrapperHTML)`
  display: flex;
  width: 100%;
  padding: ${({ padding }) => padding || '15px 25px 30px'};
  align-items: center;
  justify-content: center;
  #admin[data-is-tablet="true"] #sidebar & {
    display: none;
  }
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;

  ${props => props.sidebar && css`
    max-width: ${(props.expandedSidebar ? '80%' : '200%')};
  `}
`;

export default function Logo({ src, sidebar, expandedSidebar, ...props }) {
  return (
    <Wrapper {...props}>
      <Image
        src={src || logoSrc}
        expandedSidebar={expandedSidebar}
        sidebar={sidebar}
      />
    </Wrapper>
  );
}

export function renderLogo() {
  return <Logo />;
}

export function renderSidebarLogo({ expandedSidebar }) {
  return (
    <Logo
      padding={expandedSidebar ? '20px 30px 30px' : '30px 20px 20px'}
      expandedSidebar={expandedSidebar}
      sidebar
    />
  );
}
