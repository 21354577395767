import getKey from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import {
  parse as parseQuery,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from 'query-string';
import isFinite from 'lodash/isFinite';
import isArray from 'lodash/isArray';

import Types from '../modules/types';

const { helpers: { getTranslation } } = Types;

export const isNotCreateActivity = ['condition.check', {
  path1: 'activity',
  op: '!==',
  value2: 'create',
}];

export const getValueForFieldDefault = value => (
  value && value.length
    ? value
    : undefined
);

export function cloneItem(
  item,
  col = null,
  isAvailable = null,
  extras = {},
) {
  return [
    item[0],
    Object.assign(
      { ...item[1] },
      col ? { col: isFinite(col) ? { xs: 24, lg: col } : col } : {},
      isAvailable ? { isAvailable } : {},
      { ...extras },
    ),
  ];
}

export const ACTIONS = {
  CREATE: ['ActionCreate', {
    title: getTranslation('create'),
    messageWorking: `${getTranslation('creating')}...`,
    messageSuccess: `${getTranslation('created')}!`,
    disabled: [
      ['condition.isLoading'],
      ['condition.and', [
        ['not', ['condition.anyFieldTouched']],
        // eslint-disable-next-line max-len
        ({ searchParams }) => !searchParams.allowCreate,
        ({ searchParams, data }) => !searchParams.duplicate || !data,
      ]],
      ['condition.anyFieldHasError'],
    ],
  }],
  SAVE: ['ActionSave', {
    title: getTranslation('save'),
    messageWorking: `${getTranslation('saving')}...`,
    messageSuccess: `${getTranslation('saved')}!`,
  }],
  DELETE: ['ActionDelete', {
    title: getTranslation('delete'),
    messageWorking: `${getTranslation('deleting')}...`,
    messageSuccess: `${getTranslation('deleted')}!`,
    popconfirm: true,
  }],
  DELETE_ADMIN: ['ActionDelete', {
    title: getTranslation('delete'),
    messageWorking: `${getTranslation('deleting')}...`,
    messageSuccess: `${getTranslation('deleted')}!`,
    popconfirm: true,
    skip: [
      ['condition.checkViewer', 'role', '!==', 'ADMIN'],
    ],
  }],
};

export const filterSearch = ['FilterRegexSearch', {
  id: 'search',
  placeholder: getTranslation('search'),
  paths: ['name'],
  caseInsensitive: true,
  section: 'top',
  col: 12,
}];

export const filterSortByName = ['FilterHidden', {
  id: 'sort',
  build: builder => builder.add(
    'sort',
    { name: 1 },
  ),
}];

export const filterPagination = ['FilterPagination', {
  id: 'pagination',
  section: 'bottom',
}];

export const fieldLocation = ['FieldConnectionSelect', {
  name: 'location',
  label: null,
  prefix: `${getTranslation('locationAmbulance')}:`,
  // placeholder: getTranslation('select_an_owner'),
  url: '/locations',
  // getChoiceLabel: choice => choice.data.name,
  searchPaths: ['name'],
  skip: [
    ['condition.checkViewer', 'role', '!==', 'ADMIN'],
  ],
  // getExtraQueryConditions: () => [{ role: 'ADMIN' }],
  rules: [['validation.isRequired']],
  disabled: [isNotCreateActivity],
  virtual: [isNotCreateActivity],
}];

export const filterLocation = ['FilterField', {
  id: 'location',
  label: null,
  section: 'top',
  col: 24,
  propsFromPage: ({ client, location, history }) => ({
    client,
    location,
    history,
    // onChange: (id, value) => {
    //   const params = parseQuery(location.search);
    //   if (value && `${value}`.length) {
    //     params[id] = value;
    //   } else {
    //     delete params[id];
    //   }
    //   delete params.animal;
    //   const browserUrl = `${location.pathname
    //     }?${stringifyQuery(params)
    //     }`;
    //   history.replace(browserUrl);
    // },
  }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { location: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'location',
    url: '/locations',
    placeholder: getTranslation('find_by_location'),
    // mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
  }],
  skip: [
    (props) => props.viewer.role !== 'ADMIN',
  ],
}];

export const fieldName = ['FieldText', {
  name: 'name',
  label: null,
  prefix: `${getTranslation('name')}:`,
  rules: [['validation.isRequired']],
}];

export const fieldOwner = ['FieldConnectionSelect', {
    name: 'owner',
    label: null,
    prefix: `${getTranslation('owner')}:`,
    // placeholder: getTranslation('select_an_owner'),
    url: '/owners',
    getInitialValue: (fieldConfig, formProps) => {
      if (formProps.data) {
        const value = getKey(formProps.data, fieldConfig.name);
        if (!isUndefined(value)) {
          return value;
        }
      }
      const { owner } = parseQuery(formProps.location.search);
      if (owner) {
        return `${owner}`;
      }
      return fieldConfig.initialValue;
    },
    // getChoiceLabel: choice => choice.data.name,
    searchPaths: ['name'],
    // getExtraQueryConditions: () => [{ role: 'ADMIN' }],
    rules: [['validation.isRequired']],
    disabled: [isNotCreateActivity],
    virtual: [isNotCreateActivity],
  }];
